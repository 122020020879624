<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    scrollable
    persistent
  >
    <ProjectSprintForm
      :project-sprint="newProjectSprint"
      :errors="projectSprintValidationErrors"
      :disabled="$store.getters.loading['post:api/project-sprints']"
      @clear:errors="CLEAR_PROJECT_SPRINT_VALIDATION_ERRORS"
      @cancel="onCancel"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ProjectSprintForm from '@/components/forms/ProjectSprintForm';

export default {
  name: 'CreateProjectSprint',

  components: { ProjectSprintForm },

  computed: {
    ...mapState('projectSprints', [
      'newProjectSprint',
      'projectSprintValidationErrors',
      'projectSprintFilterParams',
    ]),
  },

  created() {
    this.SET_NEW_PROJECT_SPRINT({ project_id: +this.$route.params.projectId });
    this.SET_PROJECT_SPRINT_VALIDATION_ERRORS({});
  },

  methods: {
    ...mapActions('projectSprints', ['storeProjectSprint']),
    ...mapMutations('projectSprints', [
      'SET_NEW_PROJECT_SPRINT',
      'SET_PROJECT_SPRINT_VALIDATION_ERRORS',
      'CLEAR_PROJECT_SPRINT_VALIDATION_ERRORS',
    ]),

    onCancel(projectSprint) {
      this.SET_NEW_PROJECT_SPRINT(projectSprint);
      this.goBack();
    },

    onSave(projectSprint) {
      this.storeProjectSprint(projectSprint).then(() => {
        this.goBack();
      });
    },

    goBack() {
      this.$router.push({ name: 'project' });
    },
  },
};
</script>
